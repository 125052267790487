import {memo, useEffect, useState} from "react";
import useUser from "../Data/UserData";
import {Row, Col, Spin, Alert, notification} from 'antd';
import {LoadingOutlined} from "@ant-design/icons";
import Login from "../Auth/Login";
import Panel from "./Panel";

const Main = memo(() => {

    const { isLoggedOut, isLoading, error: authError } = useUser();

    const [oldIsLoggedOut, setOldIsLoggedOut] = useState(true);

    useEffect(() => {
        if(isLoggedOut && !oldIsLoggedOut) {

            notification['error']({
                message: 'การเข้าสู่ระบบหมดอายุ',
                description: 'คุณไม่มีการใช้งานในช่วงระยะเวลาที่ผ่านมา เพื่อความปลอดภัย กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
            })

        }

        setOldIsLoggedOut(isLoggedOut);
    }, [isLoggedOut, oldIsLoggedOut])

    if(isLoading)
        return <>
            <Row justify={'center'} style={{ marginTop: '24px' }}>
                <Col>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
                </Col>
            </Row>
        </>

    if(isLoggedOut)
        return <>
            <Login/>
        </>

    if(authError)
        return <Alert
            type='error'
            message='ขาดการเชื่อมต่อ'
            description={'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ กรุณาลองใหม่อีกครั้งภายหลัง ขออภัยในความไม่สะดวก'}
            style={{ marginTop: '12px', marginBottom: '12px' }}
            showIcon
        />

    return <>
        <Panel/>
    </>

});

export default Main;
