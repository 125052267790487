import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    --primary: #ff9318 !important;
    --danger: #ff4d4f;
    --warning: #faad14;
    --background-color: #f0f2f5;
    --lightgreen: #E8F5E9;
  }
`;

export default GlobalStyle;