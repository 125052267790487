import { BackTop, ConfigProvider } from "antd";
import Thai from "antd/lib/locale/th_TH";
import "./App.less";
import Main from "./Panel/Main";
import GlobalStyle from "./Styles/GlobalStyle";

function App() {
  return (
    <ConfigProvider locale={Thai}>
      <GlobalStyle />
      <div className="App">
        <Main />
        <BackTop />
      </div>
    </ConfigProvider>
  );
}

export default App;
