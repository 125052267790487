import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import {
    AutoComplete,
    Button,
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber,
    notification,
    Popconfirm,
    Row,
    Space,
    Spin,
    Typography
} from "antd";
import {DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import useSWR from "swr";
import axios from "axios";

const {Title} = Typography;

const EditIndustries = memo(({id, type}) => {

    const [isOpenDrawer, setOpenDrawer] = useState(false);

    const {data: data_th, mutate_th} = useSWR(isOpenDrawer ? `/backoffice/data/tb/${type}/universities/${id}/th` : null);

    const {data: allUniversitiesData} = useSWR(`universities`);

    const allUniversities = useMemo(() => {
        if (allUniversitiesData)
            return allUniversitiesData?.map(university => {
                return {
                    value: university.name
                }
            })
        return []
    }, [allUniversitiesData])


    const universities_th = useMemo(() => {
        if (data_th) {
            return {
                universities: data_th.map(university => {
                    return {
                        id: university.id,
                        name: university.university,
                        percentage: university.percentage,
                    }
                })
            }
        }
    }, [data_th])

    const [thailandForm] = Form.useForm()

    const handleSaveThailand = useCallback((values) => {
        axios.post(`/backoffice/data/tb/${type}/universities/${id}/th`, {
            universities: values.universities.sort((a, b) => a.percentage - b.percentage).reverse()
        })
            .then((res) => {
                notification.success({
                    message: 'สำเร็จ',
                    description: `บันทึกสำเร็จทั้งหมด ${res.data?.length || '-'} รายการ`
                })
            })
            .catch(e => {
                notification.error({
                    message: 'ข้อผิดพลาด',
                    description: e.response.data?.message
                })
            })
    }, [id, type])

    return <>

        <Drawer
            title="แก้ไขรายการ มหาวิทยาลัยที่ผลิตบุคลากรในตำแหน่งนี้"
            placement={'bottom'}
            onClose={() => {setOpenDrawer(false)}}
            visible={isOpenDrawer}
            height={'calc(100vh - 64px)'}
        >

            <Row>
                <Col span={24}>

                    <Title level={3}>
                        มหาวิทยาลัยที่ผลิตบุคลากรในตำแหน่งนี้ (ไทย)
                    </Title>

                    {!universities_th && <>
                        <Row justify={'center'} style={{ marginTop: '24px' }}>
                            <Col>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                            </Col>
                        </Row>
                    </>}

                    {universities_th && <>
                        <Form
                            form={thailandForm}
                            initialValues={universities_th}
                            layout={'vertical'}
                            onFinish={handleSaveThailand}
                        >
                            <Form.List
                                name={'universities'}
                            >
                                {
                                    (fields, { add, remove }) => {
                                        return <>
                                            {
                                                fields.map(field => <Space key={field.key} style={{'width': '100%'}}>

                                                    <Form.Item
                                                        name={[field.name, 'name']}
                                                        label={'ชื่อมหาวิทยาลัย'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุชื่อให้ถูกต้อง'}
                                                        ]}
                                                    >
                                                        {/*<Input placeholder={'ชื่ออาชีพ เช่น Teacher...'} />*/}
                                                        <AutoComplete
                                                            style={{ width: 400 }}
                                                            options={allUniversities}
                                                            placeholder="ชื่อมหาวิทยาลัย"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'percentage']}
                                                        label={'ร้อยละของตำแหน่งงาน'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุข้อมูล'}
                                                        ]}
                                                    >
                                                        <InputNumber min={0} step={'0.01'} placeholder={'จำนวนทศนิยม'} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'scope']}
                                                        hidden={true}
                                                        initialValue={'thailand'}
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Popconfirm
                                                        title="คุณแน่ใจว่าต้องการลบ"
                                                        onConfirm={() => {
                                                            remove(field.name)
                                                        }}
                                                        okText={'ลบ'}
                                                        cancelText={'ยกเลิก'}
                                                    >
                                                        <Button
                                                            type={'primary'}
                                                            icon={<DeleteOutlined/>}
                                                            danger
                                                        />
                                                    </Popconfirm>

                                                </Space>)
                                            }
                                            <Divider/>

                                            <Button
                                                icon={<PlusOutlined/>}
                                                onClick={() => { add() }}
                                            >
                                                เพิ่มมหาวิทยาลัย (ไทย)
                                            </Button>
                                        </>
                                    }
                                }

                            </Form.List>

                            <Button
                                type={'primary'}
                                style={{marginLeft: '8px'}}
                                icon={<SaveOutlined/>}
                                htmlType={'submit'}
                            >
                                บันทึก
                            </Button>
                        </Form>
                    </>}

                </Col>
            </Row>

        </Drawer>

        <Button
            icon={<EditOutlined/>}
            onClick={() => { setOpenDrawer(true) }}
        >
            แก้ไขมหาวิทยาลัยที่ผลิตบุคลากรในตำแหน่งนี้ (University)
        </Button>

    </>
})

export default EditIndustries;
