import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import { SWRConfig } from "swr";
import moment from "moment";

import 'moment/locale/th'

moment.locale('th')

const fetcher = (...args) => axios.get(...args).then((res) => res.data);

axios.defaults.headers.common['X-Compatability-Agent'] = "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (HTML, Gecko) Chrome/95.0.4638.55 Safari/537.11"; // spoooof

if(!!localStorage.getItem('authToken'))
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`

if(process.env.REACT_APP_API_BASE)
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
// axios.defaults.baseURL = "https://skill.kmitl.ac.th/bof";

ReactDOM.render(
  <React.StrictMode>
      <SWRConfig
          value={{
              fetcher,
              revalidateOnFocus: false,
          }}
      >
          <App />
      </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
