import { CheckOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, notification } from "antd";
import axios from "axios";
import { memo, useCallback, useState } from "react";
import useUser from "../Data/UserData.js";

const Login = memo(() => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { mutate: mutateUser } = useUser();

  const handleLogin = useCallback(
    async (credentials) => {
      setIsLoggingIn(true);
      axios
        .post("/backoffice/login", {
          ...credentials,
        })
        .then(({ data }) => {
          localStorage.setItem("authToken", data.data?.token);
          axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
            "authToken"
          )}`;

          notification["success"]({
            message: "สำเร็จ",
            description: "เข้าสู่ระบบสำเร็จ ระบบกำลังพาท่านไปหน้าจัดการข้อมูล",
          });

          setTimeout(() => {
            // window.location.reload();
            mutateUser();
          }, 1500);
        })
        .catch((e) => {
          notification["error"]({
            message: "เข้าสู่ระบบไม่สำเร็จ",
            description: 'ไม่สามารถเข้าสู่ระบบได้ "ชื่อผู้ใช้" หรือ "รหัสผ่าน" ไม่ถูกต้อง',
          });
          setIsLoggingIn(false);
          // console.error(e.toString())
        });
    },
    [setIsLoggingIn]
  );

  return (
    <>
      <Row justify={"center"}>
        <Col span={24} md={16} lg={10}>
          <Card
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20vh",
            }}
          >
            <div style={{ textAlign: "center", textDecoration: "underline orange" }}>
              <h2>ระบบจัดการฐานข้อมูล Skill Mapping</h2>
            </div>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={handleLogin}
              autoComplete="off"
            >
              <Form.Item
                label="ชื่อผู้ใช้"
                name="username"
                rules={[{ required: true, message: "กรุณากรอกชื่อผู้ใช้ เป็นตัวเลข 6" }]}
                help={"กรอกชื่อผู้ใช้สำหรับเข้าสู่ระบบ"}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="รหัสผ่าน"
                name="password"
                rules={[{ required: true, message: "กรุณากรอกรหัสผ่าน" }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={isLoggingIn}>
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
              <CheckOutlined style={{ color: "green" }} /> ลงประกาศหลักสูตรที่เปิดสอน
              สำหรับสถาบันการศึกษา
              <br />
              <CheckOutlined style={{ color: "green" }} /> ให้คำแนะนำด้านทักษะแต่ละสายอาชีพ
              สำหรับผู้ทรงคุณวุฒิ
              <br />
              {/* <CheckOutlined style={{"color": "green"}} /> แบบสำรวจความต้องการทางบุคลากร<br/> */}
              {/* <CheckOutlined style={{"color": "green"}} /> ลงประกาศรับสมัครงาน<br/> */}
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
});

export default Login;
