
import useSWR from 'swr';

export default function useUser() {
    const { data, error, mutate } = useSWR('/backoffice/auth', {
        revalidateOnFocus: true,
        revalidateIfStale: true,
        shouldRetryOnError: false,
    });

    const isLoading = !data && !error;
    const isLoggedOut = !data || (error && (error.response?.status === 403 || error.response?.status === 401));

    return {
        isLoading,
        isLoggedOut,
        user: data?.data,
        error,
        mutate
    }
}

