import {memo, useCallback, useMemo, useState} from "react";
import useSWR from "swr";
import {Button, Col, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Select, Space, Table} from "antd";
import axios from "axios";
import {DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import EditCommonCareers from "./DataManagement/EditCommonCareers.jsx";
import EditSkills from "./DataManagement/EditSkills.jsx";
import EditJobPosts from "./DataManagement/EditJobPosts.jsx";
import EditSkillGrowth from "./DataManagement/EditSkillGrowth.jsx";
import EditIndustries from "./DataManagement/EditIndustries.jsx";
import EditUniversities from "./DataManagement/EditUniversities.jsx";
import EditIndustriesJobPosts from "./DataManagement/EditIndustriesJobPosts.jsx";

const EditCareersTable = memo(() => {

    const {data: groups, mutate: mutateGroup} = useSWR(`/backoffice/data/group`)
    const {data: sub_groups, mutate: mutateSubGroups} = useSWR(`/backoffice/data/sub_group`)
    const { data: careersData, mutate: mutateCareer } = useSWR(`/backoffice/data/career`);

    const [editingCareerKey, setEditingCareerKey] = useState(-1);
    
    const [openCreateCareerModal, setOpenCreateCareerModal] = useState(false);
    const [isCreatingLoading, setIsCreatingLoading] = useState(false);

    const [createCareerForm] = Form.useForm();
    const [editCareerForm] = Form.useForm();

    const careers = useMemo(() => {
        return careersData?.map(career => {
            return {
                id: career.id,
                name: career.original_name,
                sub_groups: career.sub_groups,
            }
        })
    }, [careersData])

    const isEditingCareer = useCallback((record) => {
        return record.id === editingCareerKey
    }, [editingCareerKey])

    const handleCreateCareer = useCallback((groupName, sub_groups) => {

        setIsCreatingLoading(true);

        axios.post('/backoffice/data/career', {
            name: groupName,
            sub_groups: sub_groups
        })
            .then(res => {
                // console.log(res.data)
                notification.success({
                    'message': 'สำเร็จ',
                    'description': 'สร้างอาชีพใหม่สำเร็จ'
                })
                createCareerForm.resetFields();
                setOpenCreateCareerModal(false)
                mutateCareer()
            })
            .catch(e => {
                notification.error({
                    'message': 'เกิดข้อผิดพลาด',
                    'description': e.response.data?.message
                })
            })
            .finally(() => {
                setIsCreatingLoading(false);
            })

    }, [createCareerForm, mutateCareer]);

    const CareerTableColumns = [
        {
            title: 'ลำดับ',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'ชื่ออาชีพ',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => isEditingCareer(record) ? <>
                <Form.Item
                    name={'name'}
                    rules={[{required: true, message: 'โปรดระบุชื่ออาชีพ'}]}
                >
                    <Input/>
                </Form.Item>
            </> : <strong>{value}</strong>
        },
        {
            title: 'กลุ่มย่อย',
            dataIndex: 'sub_groups',
            key: 'sub_groups',
            render: (value, record) => isEditingCareer(record) ? <>
                <Form.Item
                    name={'sub_groups'}
                >
                    <Select
                        mode={'multiple'}
                        placeholder={'เลือกกลุ่มย่อย'}
                        allowClear
                    >
                        {
                            sub_groups?.map(sub_group => <Select.Option
                                value={sub_group.id}
                                key={sub_group.id}
                            >
                                {sub_group.name}
                            </Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </> : <>
                {
                    value.map(sub_group => sub_group.name).join(", ")
                }
            </>
        },
        {
            title: 'แก้ไข',
            key: 'action',
            render: (text, record) => isEditingCareer(record) ? <>
                <Button
                    type={'primary'}
                    size={'small'}
                    icon={<SaveOutlined/>}
                    onClick={() => {
                        Modal.confirm({
                            title: 'ยืนยัน',
                            content: `คุณต้องการบันทึกการแก้ไขข้อมูลของอาชีพ ${record.name}`,
                            onOk: () => {
                                return new Promise((resolve, reject) => {
                                    editCareerForm
                                        .validateFields()
                                        .then((values) => {
                                            axios.patch(`/backoffice/data/career/${record.id}`, values)
                                                .then(res => {
                                                    notification.success({
                                                        message: 'สำเร็จ',
                                                        description: 'แก้ไขข้อมูลสำเร็จ'
                                                    })
                                                    editCareerForm.resetFields()
                                                    setEditingCareerKey(-1);
                                                    mutateCareer()
                                                    resolve()
                                                })
                                                .catch(e => {
                                                    notification.error({
                                                        message: 'เกิดข้อผิดพลาด',
                                                        description: e.response.data.message
                                                    })
                                                    reject()
                                                })
                                        })
                                        .catch(e => {
                                            notification.warn({
                                                message: 'คำเตือน',
                                                description: 'โปรดตรวจสอบข้อมูลให้ถูกต้องก่อนบันทึกรายการ'
                                            })
                                            reject()
                                        })
                                })
                            },
                            okText: 'บันทึก',
                            cancelText: 'ยกเลิก'
                        })
                    }}
                >
                    บันทึก
                </Button>
                <Button
                    size={'small'}
                    onClick={() => {
                        editCareerForm.resetFields()
                        setEditingCareerKey(-1);
                    }}
                >
                    ยกเลิก
                </Button>
            </> : <>
                <Button
                    type={'primary'}
                    size={'small'}
                    icon={<EditOutlined/>}
                    disabled={editingCareerKey !== -1 && editingCareerKey !== record.id}
                    onClick={() => {
                        editCareerForm.setFieldsValue({
                            id: record.id,
                            name: record.name,
                            sub_groups: record.sub_groups.map(sub_group => sub_group.id),
                        })
                        setEditingCareerKey(record.id)
                    }}
                >
                    แก้ไข
                </Button>
                <Popconfirm
                    title={`ยืนยันที่จะลบ ${record.name}`}
                    onConfirm={() => {
                        let request = axios.delete(`/backoffice/data/career/${record.id}`)
                        request.then(() => {
                            mutateCareer()
                        })
                        return request
                    }}
                    okText="ลบ"
                    cancelText="ยกเลิก"
                >
                    <Button
                        size={'small'}
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </>
        },
    ];

    return <>

        <Modal
            visible={openCreateCareerModal}
            title="สร้างอาชีพใหม่"
            okText="สร้าง"
            cancelText="ยกเลิก"
            confirmLoading={isCreatingLoading}
            onCancel={() => {
                setOpenCreateCareerModal(false)
            }}
            onOk={() => {
                createCareerForm
                    .validateFields()
                    .then(values => {
                        // console.log(values)
                        handleCreateCareer(values.name, values.sub_groups);
                    })
            }}
        >
            <Form
                form={createCareerForm}
                layout="vertical"
                name="createGroupForm"
            >
                <Form.Item
                    name="name"
                    label="ชื่ออาชีพ"
                    rules={[{required: true, message: 'โปรดระบุชื่ออาชีพที่ต้องการสร้าง'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="sub_groups"
                    label="กลุ่มย่อย"
                >
                    <Select
                        mode={'multiple'}
                        placeholder={'เลือกกลุ่มย่อย'}
                        allowClear
                    >
                        {
                            sub_groups?.map(sub_group => <Select.Option
                                value={sub_group.id}
                                key={sub_group.id}
                            >
                                {sub_group.name}
                            </Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>

        <Row justify={'end'} style={{marginBottom: '24px'}}>
            <Col>
                <Space>
                    <Button
                        type={'primary'}
                        onClick={() => {
                            setOpenCreateCareerModal(true);
                        }}
                    >
                        <PlusOutlined/> สร้างอาชีพใหม่
                    </Button>
                </Space>
            </Col>
        </Row>

        <Form
            form={editCareerForm}
            name="editGroupForm"
        >
            <Table
                columns={CareerTableColumns}
                loading={!careers}
                dataSource={careers}
                rowKey={'id'}
                expandable={{
                    expandedRowRender: record => <>
                        <Space size={[8, 16]} wrap>
                            <EditSkills id={record.id} type={'career'} />
                            <EditJobPosts id={record.id} type={'career'} />
                            <EditSkillGrowth id={record.id} type={'career'} />
                            <EditIndustries id={record.id} type={'career'} />
                            <EditUniversities id={record.id} type={'career'} />
                            <EditIndustriesJobPosts id={record.id} type={'career'} />
                        </Space>
                    </>,
                }}
            />
        </Form>

    </>;
});

export default EditCareersTable;
