import {
  EditOutlined,
  FileTextOutlined,
  LoadingOutlined,
  LockOutlined,
  ProjectOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { Suspense, memo, useCallback, useMemo, useState } from "react";

import axios from "axios";
import useUser from "../Data/UserData";
import "../Styles/Panel.css";
import ManageSkillMapping from "./ManageSkillMapping.jsx";

const Courses = React.lazy(() => import("./Courses.jsx"));
const Advisor = React.lazy(() => import("./Advisor.jsx"));
const AdviceSummary = React.lazy(() => import("./AdviceSummary.jsx"));
const AddUser = React.lazy(() => import("./AddUser.jsx"));
const SystemSetting = React.lazy(() => import("./SystemSetting.jsx"));
const PasswordReset = React.lazy(() => import("./PasswordReset.jsx"));

// import Courses from "./Courses";
// import Advisor from "./Advisor.jsx";
// import AdviceSummary from "./AdviceSummary.jsx";
// import AddUser from "./AddUser.jsx";
// import SystemSetting from "./SystemSetting.jsx";

const { Header, Footer, Sider, Content } = Layout;

const Panel = memo(() => {
  const menus = [
    {
      name: "manage",
      label: "จัดการคอร์สและหลักสูตร",
      icon: <FileTextOutlined />,
      roles: [0, 99],
    },
    {
      name: "advice",
      label: "คำแนะนำด้านทักษะอาชีพ",
      icon: <EditOutlined />,
      roles: [1, 99],
    },
    {
      name: "advice-summary",
      label: "สรุปคำแนะนำด้านทักษะอาชีพ",
      icon: <ProjectOutlined />,
      roles: [99],
    },
    {
      name: "user-add",
      label: "เพิ่มผู้ใช้ในระบบ",
      icon: <UserAddOutlined />,
      roles: [99],
    },
    {
      name: "edit-skills",
      label: "แก้ไขฐานข้อมูล Skill Mapping",
      icon: <EditOutlined />,
      roles: [99],
    },
    {
      name: "reset-password",
      label: "เปลี่ยนรหัสผ่าน",
      icon: <LockOutlined />,
    },
    // {
    //     name: "setting",
    //     label: "จัดการข้อมูลระบบ",
    //     icon: <SettingOutlined />,
    //     roles: [99],
    // },
  ];

  const { user } = useUser();

  const handleLogout = useCallback(() => {
    axios
      .post("/backoffice/logout")
      .then((res) => {
        localStorage.removeItem("authToken");
        window.location.reload();
      })
      .catch((e) => {
        notification["error"]({
          message: "ออกจากระบบไม่สำเร็จ",
          description: `ไม่สามารถออกจากระบบได้ กรุณาลองอีกครั้ง : ${e.toString()}`,
        });
      });
  }, []);

  const availableMenus = useMemo(() => {
    return menus.filter((menu) => !menu.roles || menu.roles.includes(user.role));
  }, [user]);

  const [currentPage, setCurrentPage] = useState(availableMenus?.[0]?.name);

  if (user)
    return (
      <>
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            width={300}
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={{
                width: "240px",
                padding: "8px",
                display: "flex",
              }}
            >
              <img
                src="./images/TSRI_Logo.png"
                alt="TSRI logo"
                style={{
                  width: "40%",
                  margin: "auto",
                }}
              />
            </div>
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={availableMenus?.[0]?.name}
              onSelect={(selected) => {
                setCurrentPage(selected.key);
              }}
            >
              {availableMenus
                .map((menu) => {
                  // if(menu.roles.includes(user.role))
                  return (
                    <Menu.Item key={menu.name} icon={menu.icon}>
                      {menu.label}
                    </Menu.Item>
                  );
                })
                .filter((e) => e != null)}

              {/*<Menu.Item key="manageAll" disabled icon={<FileTextOutlined/>}>*/}
              {/*    จัดการหลักสูตรทั้งหมด*/}
              {/*</Menu.Item>*/}
              {/*<Menu.Item key="editProfile" disabled icon={<EditOutlined/>}>*/}
              {/*    แก้ไขข้อมูลผู้ใช้*/}
              {/*</Menu.Item>*/}
            </Menu>
          </Sider>
          <Layout>
            <Header style={{ padding: 0, backgroundColor: "white" }}>
              <Row justify={"end"} align={"middle"}>
                <Col>
                  <Dropdown
                    overlay={
                      <>
                        <Menu>
                          <Menu.Item danger onClick={handleLogout}>
                            ออกจากระบบ
                          </Menu.Item>
                        </Menu>
                      </>
                    }
                    trigger={["click"]}
                  >
                    <Button type="link">
                      <Typography.Text ellipsis style={{ marginRight: "10px", textAlign: "right" }}>
                        {user.name} {user.last_name}
                      </Typography.Text>
                      <Avatar icon={<UserOutlined />} />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content style={{ margin: "24px 16px 0" }}>
              <Suspense
                fallback={
                  <>
                    <Row justify={"center"} style={{ marginTop: "24px" }}>
                      <Col>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
                      </Col>
                    </Row>
                  </>
                }
              >
                {currentPage === "manage" && (
                  <>
                    <Courses />
                  </>
                )}
                {currentPage === "advice" && (
                  <>
                    <Advisor />
                  </>
                )}
                {currentPage === "advice-summary" && (
                  <>
                    <AdviceSummary />
                  </>
                )}
                {currentPage === "user-add" && (
                  <>
                    <AddUser />
                  </>
                )}
                {currentPage === "setting" && (
                  <>
                    <SystemSetting />
                  </>
                )}
                {currentPage === "reset-password" && (
                  <>
                    <PasswordReset />
                  </>
                )}
                {currentPage === "edit-skills" && (
                  <>
                    <ManageSkillMapping />
                  </>
                )}
              </Suspense>
            </Content>
            <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
              © 2022 สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม
            </Footer>
          </Layout>
        </Layout>
      </>
    );
});

export default Panel;
