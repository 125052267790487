import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import {
    AutoComplete,
    Button,
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber,
    notification,
    Popconfirm,
    Row,
    Space,
    Spin,
    Typography
} from "antd";
import {DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import useSWR from "swr";
import axios from "axios";

const {Title} = Typography;

const EditJobPosts = memo(({id, type}) => {

    const [isOpenDrawer, setOpenDrawer] = useState(false);

    const {data, mutate} = useSWR(isOpenDrawer ? `/backoffice/data/tb/${type}/job_posts/${id}` : null);
    const {data: data_th, mutate_th} = useSWR(isOpenDrawer ? `/backoffice/data/tb/${type}/job_posts/${id}/th` : null);

    const {data: allSkillsData} = useSWR(`skills`);

    const allSkills = useMemo(() => {
        if (allSkillsData)
            return allSkillsData?.map(skill => {
                return {
                    value: skill.name
                }
            })
        return []
    }, [allSkillsData])

    const skills = useMemo(() => {
        if (data) {
            return {
                skills: data.map(skill => {
                    return {
                        id: skill.id,
                        name: skill.skill,
                        amount: skill.amount,
                        percentage: skill.percentage,
                    }
                })
            }
        }
    }, [data])

    const skills_th = useMemo(() => {
        if (data_th) {
            return {
                skills: data_th.map(skill => {
                    return {
                        id: skill.id,
                        name: skill.skill,
                        amount: skill.amount,
                        percentage: skill.percentage,
                    }
                })
            }
        }
    }, [data_th])

    const [globalForm] = Form.useForm()
    const [thailandForm] = Form.useForm()

    const handleSaveGlobal = useCallback((values) => {
        axios.post(`/backoffice/data/tb/${type}/job_posts/${id}`, {
            skills: values.skills.sort((a, b) => a.amount - b.amount).reverse()
        })
            .then((res) => {
                notification.success({
                    message: 'สำเร็จ',
                    description: `บันทึกสำเร็จทั้งหมด ${res.data?.length || '-'} รายการ`
                })
            })
            .catch(e => {
                notification.error({
                    message: 'ข้อผิดพลาด',
                    description: e.response.data?.message
                })
            })
    }, [id, type])

    const handleSaveThailand = useCallback((values) => {
        axios.post(`/backoffice/data/tb/${type}/job_posts/${id}/th`, {
            skills: values.skills.sort((a, b) => a.amount - b.amount).reverse()
        })
            .then((res) => {
                notification.success({
                    message: 'สำเร็จ',
                    description: `บันทึกสำเร็จทั้งหมด ${res.data?.length || '-'} รายการ`
                })
            })
            .catch(e => {
                notification.error({
                    message: 'ข้อผิดพลาด',
                    description: e.response.data?.message
                })
            })
    }, [id, type])

    return <>

        <Drawer
            title="แก้ไขรายการ ทักษะในประกาศรับสมัครงาน"
            placement={'bottom'}
            onClose={() => {setOpenDrawer(false)}}
            visible={isOpenDrawer}
            height={'calc(100vh - 64px)'}
        >

            <Row>
                <Col span={24} xl={12}>

                    <Title level={3}>
                        รายการทักษะ (ทั่วโลก)
                    </Title>

                    {!skills && <>
                        <Row justify={'center'} style={{ marginTop: '24px' }}>
                            <Col>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                            </Col>
                        </Row>
                    </>}

                    {skills && <>
                        <Form
                            form={globalForm}
                            initialValues={skills}
                            layout={'vertical'}
                            onFinish={handleSaveGlobal}
                        >
                            <Form.List
                                name={'skills'}
                            >
                                {
                                    (fields, { add, remove }) => {
                                        return <>
                                            {
                                                fields.map(field => <Space key={field.key} style={{'width': '100%'}}>

                                                    <Form.Item
                                                        name={[field.name, 'name']}
                                                        label={'ชื่อทักษะ'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุชื่อทักษะให้ถูกต้อง'}
                                                        ]}
                                                    >
                                                        {/*<Input placeholder={'ชื่อทักษะ เช่น Teacher...'} />*/}
                                                        <AutoComplete
                                                            style={{ width: 250 }}
                                                            options={allSkills}
                                                            placeholder="ชื่อทักษะ"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'amount']}
                                                        label={'จำนวนผู้ที่มีทักษะ'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุข้อมูล'}
                                                        ]}
                                                    >
                                                        <InputNumber min={0} placeholder={'จำนวน'} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'percentage']}
                                                        label={'ร้อยละ'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุข้อมูล'}
                                                        ]}
                                                    >
                                                        <InputNumber min={0} max={100} step={'0.01'} placeholder={'จำนวน 0.00 ถึง 100.00'} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'scope']}
                                                        hidden={true}
                                                        initialValue={'global'}
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Popconfirm
                                                        title="คุณแน่ใจว่าต้องการลบ"
                                                        onConfirm={() => {
                                                            remove(field.name)
                                                        }}
                                                        okText={'ลบ'}
                                                        cancelText={'ยกเลิก'}
                                                    >
                                                        <Button
                                                            type={'primary'}
                                                            icon={<DeleteOutlined/>}
                                                            danger
                                                        />
                                                    </Popconfirm>

                                                </Space>)
                                            }
                                            <Divider/>
                                            <Button
                                                icon={<PlusOutlined/>}
                                                onClick={() => { add() }}
                                            >
                                                เพิ่มอาชีพ (ทั่วโลก)
                                            </Button>
                                        </>
                                    }
                                }

                            </Form.List>

                            <Button
                                type={'primary'}
                                style={{marginLeft: '8px'}}
                                icon={<SaveOutlined/>}
                                htmlType={'submit'}
                            >
                                บันทึก
                            </Button>
                        </Form>
                    </>}

                </Col>
                <Col span={24} lg={12}>

                    <Title level={3}>
                        รายการทักษะ (ไทย)
                    </Title>

                    {!skills_th && <>
                        <Row justify={'center'} style={{ marginTop: '24px' }}>
                            <Col>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                            </Col>
                        </Row>
                    </>}

                    {skills_th && <>
                        <Form
                            form={thailandForm}
                            initialValues={skills_th}
                            layout={'vertical'}
                            onFinish={handleSaveThailand}
                        >
                            <Form.List
                                name={'skills'}
                            >
                                {
                                    (fields, { add, remove }) => {
                                        return <>
                                            {
                                                fields.map(field => <Space key={field.key} style={{'width': '100%'}}>

                                                    <Form.Item
                                                        name={[field.name, 'name']}
                                                        label={'ชื่อทักษะ'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุชื่อทักษะให้ถูกต้อง'}
                                                        ]}
                                                    >
                                                        {/*<Input placeholder={'ชื่อทักษะ เช่น Teacher...'} />*/}
                                                        <AutoComplete
                                                            style={{ width: 250 }}
                                                            options={allSkills}
                                                            placeholder="ชื่อทักษะ"
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'amount']}
                                                        label={'จำนวนผู้ที่มีทักษะ'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุข้อมูล'}
                                                        ]}
                                                    >
                                                        <InputNumber min={0} placeholder={'จำนวน'} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'percentage']}
                                                        label={'ร้อยละ'}
                                                        rules={[
                                                            {required: true, message: 'โปรดระบุข้อมูล'}
                                                        ]}
                                                    >
                                                        <InputNumber min={0} max={100} step={'0.01'} placeholder={'จำนวน 0.00 ถึง 100.00'} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[field.name, 'scope']}
                                                        hidden={true}
                                                        initialValue={'thailand'}
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Popconfirm
                                                        title="คุณแน่ใจว่าต้องการลบ"
                                                        onConfirm={() => {
                                                            remove(field.name)
                                                        }}
                                                        okText={'ลบ'}
                                                        cancelText={'ยกเลิก'}
                                                    >
                                                        <Button
                                                            type={'primary'}
                                                            icon={<DeleteOutlined/>}
                                                            danger
                                                        />
                                                    </Popconfirm>

                                                </Space>)
                                            }
                                            <Divider/>

                                            <Button
                                                icon={<PlusOutlined/>}
                                                onClick={() => { add() }}
                                            >
                                                เพิ่มอาชีพ (ไทย)
                                            </Button>
                                        </>
                                    }
                                }

                            </Form.List>

                            <Button
                                type={'primary'}
                                style={{marginLeft: '8px'}}
                                icon={<SaveOutlined/>}
                                htmlType={'submit'}
                            >
                                บันทึก
                            </Button>
                        </Form>
                    </>}

                </Col>
            </Row>

        </Drawer>

        <Button
            icon={<EditOutlined/>}
            onClick={() => { setOpenDrawer(true) }}
        >
            แก้ไขจำนวนทักษะในประกาศรับสมัครงาน (Skill in Job Posts)
        </Button>

    </>
})

export default EditJobPosts;
