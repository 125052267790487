import {memo, useCallback, useState} from "react";
import {
    Button,
    Col,
    Row,
    Space,
    Typography,
    Modal,
    Form,
    Input,
    notification,
    Table,
    Switch,
    InputNumber, Tabs, Select, Popconfirm
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    PlusOutlined,
    SaveOutlined
} from "@ant-design/icons";
import axios from "axios";
import useSWR from "swr";
import EditCommonCareers from "../Components/DataManagement/EditCommonCareers.jsx";

import '../Styles/CardContainer.scss'
import EditSkills from "../Components/DataManagement/EditSkills.jsx";
import EditJobPosts from "../Components/DataManagement/EditJobPosts.jsx";
import EditSkillGrowth from "../Components/DataManagement/EditSkillGrowth.jsx";
import EditIndustries from "../Components/DataManagement/EditIndustries.jsx";
import EditUniversities from "../Components/DataManagement/EditUniversities.jsx";
import EditIndustriesJobPosts from "../Components/DataManagement/EditIndustriesJobPosts.jsx";
import EditCareersTable from "../Components/EditCareersTable.jsx";

const {Title} = Typography;

const ManageSkillMapping = memo(() => {

    const {data: groups, mutate: mutateGroup} = useSWR(`/backoffice/data/group`)
    const {data: sub_groups, mutate: mutateSubGroups} = useSWR(`/backoffice/data/sub_group`)

    const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
    const [openCreateSubGroupModal, setOpenCreateSubGroupModal] = useState(false);
    const [isCreatingLoading, setIsCreatingLoading] = useState(false);

    const [editingGroupKey, setEditingGroupKey] = useState(-1);
    const [editingSubGroupKey, setEditingSubGroupKey] = useState(-1);

    const [createGroupForm] = Form.useForm();
    const [createSubGroupForm] = Form.useForm();
    const [groupEditingForm] = Form.useForm();
    const [SubGroupEditingForm] = Form.useForm();

    const isEditingGroup = useCallback((record) => {
        return record.id === editingGroupKey
    }, [editingGroupKey])

    const isEditingSubGroup = useCallback((record) => {
        return record.id === editingSubGroupKey
    }, [editingSubGroupKey])

    const handleCreateGroup = useCallback((groupName) => {

        setIsCreatingLoading(true);

        axios.post('/backoffice/data/group', {
            name: groupName
        })
            .then(res => {
                notification.success({
                    'message': 'สำเร็จ',
                    'description': 'สร้างกลุ่มใหม่สำเร็จ'
                })
                createGroupForm.resetFields();
                setOpenCreateGroupModal(false)
                mutateGroup()
            })
            .catch(e => {
                notification.error({
                    'message': 'เกิดข้อผิดพลาด',
                    'description': e.response.data?.message
                })
            })
            .finally(() => {
                setIsCreatingLoading(false);
            })

    }, [createGroupForm, mutateGroup]);

    const handleCreateSubGroup = useCallback((groupName, group_id) => {

        setIsCreatingLoading(true);

        axios.post('/backoffice/data/sub_group', {
            name: groupName,
            group_id: group_id
        })
            .then(res => {
                notification.success({
                    'message': 'สำเร็จ',
                    'description': 'สร้างกลุ่มย่อยใหม่สำเร็จ'
                })
                createSubGroupForm.resetFields();
                setOpenCreateSubGroupModal(false)
                mutateSubGroups()
            })
            .catch(e => {
                notification.error({
                    'message': 'เกิดข้อผิดพลาด',
                    'description': e.response.data?.message
                })
            })
            .finally(() => {
                setIsCreatingLoading(false);
            })

    }, [createSubGroupForm, mutateSubGroups]);

    const GroupTableColumns = [
        {
            title: 'ลำดับ',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'ชื่อกลุ่ม',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => isEditingGroup(record) ? <>
                <Form.Item
                    name={'name'}
                    rules={[{required: true, message: 'โปรดระบุชื่อกลุ่ม'}]}
                >
                    <Input/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนกลุ่มตัวอย่าง',
            dataIndex: 'sample',
            key: 'sample',
            render: (value, record) => isEditingGroup(record) ? <>
                <Form.Item
                    name={'sample'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนกลุ่มตัวอย่าง (ไทย)',
            dataIndex: 'sample_th',
            key: 'sample_th',
            render: (value, record) => isEditingGroup(record) ? <>
                <Form.Item
                    name={'sample_th'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนประกาศรับสมัครงาน',
            dataIndex: 'job_posts_sample',
            key: 'job_posts_sample',
            render: (value, record) => isEditingGroup(record) ? <>
                <Form.Item
                    name={'job_posts_sample'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนประกาศรับสมัครงาน (ไทย)',
            dataIndex: 'job_posts_sample_th',
            key: 'job_posts_sample_th',
            render: (value, record) => isEditingGroup(record) ? <>
                <Form.Item
                    name={'job_posts_sample_th'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'แสดงผล',
            dataIndex: 'is_visible',
            key: 'is_visible',
            render: (is_visible, record) => {
                return <>
                    <Switch
                        checkedChildren={<EyeOutlined/>}
                        unCheckedChildren={<EyeInvisibleOutlined/>}
                        defaultChecked={is_visible}
                        onChange={(checked, event) => {
                            axios.patch(`/backoffice/data/group/${record.id}`, {
                                is_visible: checked
                            })
                                .then((res) => {
                                    notification.success({
                                        message: 'สำเร็จ',
                                        description: `${res.data?.is_visible === true ? 'แสดงผลกลุ่ม' : 'ซ่อนการแสดงผลกลุ่ม'} ${record.name} ที่หน้า Skill Mapping แล้ว`
                                    })
                                    mutateGroup()
                                })
                                .catch((e) => {
                                    notification.error({
                                        message: 'เกิดข้อผิดพลาด',
                                        description: e.response.data.message
                                    })
                                })
                        }}
                    />
                </>
            }
        },
        {
            title: 'แก้ไข',
            key: 'action',
            render: (text, record) => isEditingGroup(record) ? <>
                <Button
                    type={'primary'}
                    size={'small'}
                    icon={<SaveOutlined/>}
                    onClick={() => {
                        Modal.confirm({
                            title: 'ยืนยัน',
                            content: `คุณต้องการบันทึกการแก้ไขข้อมูลของกลุ่ม ${record.name}`,
                            onOk: () => {
                                return new Promise((resolve, reject) => {
                                    groupEditingForm
                                        .validateFields()
                                        .then((values) => {
                                            axios.patch(`/backoffice/data/group/${record.id}`, values)
                                                .then(res => {
                                                    notification.success({
                                                        message: 'สำเร็จ',
                                                        description: 'แก้ไขข้อมูลสำเร็จ'
                                                    })
                                                    groupEditingForm.resetFields()
                                                    setEditingGroupKey(-1);
                                                    mutateGroup()
                                                    resolve()
                                                })
                                                .catch(e => {
                                                    notification.error({
                                                        message: 'เกิดข้อผิดพลาด',
                                                        description: e.response.data.message
                                                    })
                                                    reject()
                                                })
                                        })
                                        .catch(e => {
                                            notification.warn({
                                                message: 'คำเตือน',
                                                description: 'โปรดตรวจสอบข้อมูลให้ถูกต้องก่อนบันทึกรายการ'
                                            })
                                            reject()
                                        })
                                })
                            },
                            okText: 'บันทึก',
                            cancelText: 'ยกเลิก'
                        })
                    }}
                >
                    บันทึก
                </Button>
                <Button
                    size={'small'}
                    onClick={() => {
                        groupEditingForm.resetFields()
                        setEditingGroupKey(-1);
                    }}
                >
                    ยกเลิก
                </Button>
            </> : <>
                <Button
                    type={'primary'}
                    size={'small'}
                    icon={<EditOutlined/>}
                    disabled={editingGroupKey !== -1 && editingGroupKey !== record.id}
                    onClick={() => {
                        groupEditingForm.setFieldsValue(record)
                        setEditingGroupKey(record.id)
                    }}
                >
                    แก้ไข
                </Button>
                <Popconfirm
                    title={`ยืนยันที่จะลบ ${record.name}`}
                    onConfirm={() => {
                        let request = axios.delete(`/backoffice/data/group/${record.id}`)
                        request.then(() => {
                            mutateGroup()
                        })
                        return request
                    }}
                    okText="ลบ"
                    cancelText="ยกเลิก"
                >
                    <Button
                        size={'small'}
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </>
        },
    ];

    const SubGroupTableColumns = [
        {
            title: 'ลำดับ',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'ชื่อกลุ่มหลัก',
            dataIndex: ['group', 'name'],
            key: 'group.name',
        },
        {
            title: 'ชื่อกลุ่มย่อย',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => isEditingSubGroup(record) ? <>
                <Form.Item
                    name={'name'}
                    rules={[{required: true, message: 'โปรดระบุชื่อกลุ่ม'}]}
                >
                    <Input/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนกลุ่มตัวอย่าง',
            dataIndex: 'sample',
            key: 'sample',
            render: (value, record) => isEditingSubGroup(record) ? <>
                <Form.Item
                    name={'sample'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนกลุ่มตัวอย่าง (ไทย)',
            dataIndex: 'sample_th',
            key: 'sample_th',
            render: (value, record) => isEditingSubGroup(record) ? <>
                <Form.Item
                    name={'sample_th'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนประกาศรับสมัครงาน',
            dataIndex: 'job_posts_sample',
            key: 'job_posts_sample',
            render: (value, record) => isEditingSubGroup(record) ? <>
                <Form.Item
                    name={'job_posts_sample'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'จำนวนประกาศรับสมัครงาน (ไทย)',
            dataIndex: 'job_posts_sample_th',
            key: 'job_posts_sample_th',
            render: (value, record) => isEditingSubGroup(record) ? <>
                <Form.Item
                    name={'job_posts_sample_th'}
                >
                    <InputNumber/>
                </Form.Item>
            </> : (value || '-')
        },
        {
            title: 'แก้ไข',
            key: 'action',
            render: (text, record) => isEditingSubGroup(record) ? <>
                <Button
                    type={'primary'}
                    size={'small'}
                    icon={<SaveOutlined/>}
                    onClick={() => {
                        Modal.confirm({
                            title: 'ยืนยัน',
                            content: `คุณต้องการบันทึกการแก้ไขข้อมูลของกลุ่มย่อย ${record.name}`,
                            onOk: () => {
                                return new Promise((resolve, reject) => {
                                    SubGroupEditingForm
                                        .validateFields()
                                        .then((values) => {
                                            axios.patch(`/backoffice/data/sub_group/${record.id}`, values)
                                                .then(res => {
                                                    notification.success({
                                                        message: 'สำเร็จ',
                                                        description: 'แก้ไขข้อมูลสำเร็จ'
                                                    })
                                                    SubGroupEditingForm.resetFields()
                                                    setEditingSubGroupKey(-1);
                                                    mutateSubGroups()
                                                    resolve()
                                                })
                                                .catch(e => {
                                                    notification.error({
                                                        message: 'เกิดข้อผิดพลาด',
                                                        description: e.response.data.message
                                                    })
                                                    reject()
                                                })
                                        })
                                        .catch(e => {
                                            notification.warn({
                                                message: 'คำเตือน',
                                                description: 'โปรดตรวจสอบข้อมูลให้ถูกต้องก่อนบันทึกรายการ'
                                            })
                                            reject()
                                        })
                                })
                            },
                            okText: 'บันทึก',
                            cancelText: 'ยกเลิก'
                        })
                    }}
                >
                    บันทึก
                </Button>
                <Button
                    size={'small'}
                    onClick={() => {
                        SubGroupEditingForm.resetFields()
                        setEditingSubGroupKey(-1);
                    }}
                >
                    ยกเลิก
                </Button>
            </> : <>
                <Button
                    type={'primary'}
                    size={'small'}
                    icon={<EditOutlined/>}
                    disabled={editingSubGroupKey !== -1 && editingSubGroupKey !== record.id}
                    onClick={() => {
                        SubGroupEditingForm.setFieldsValue(record)
                        setEditingSubGroupKey(record.id)
                    }}
                >
                    แก้ไข
                </Button>
                <Popconfirm
                    title={`ยืนยันที่จะลบ ${record.name}`}
                    onConfirm={() => {
                        let request = axios.delete(`/backoffice/data/sub_group/${record.id}`)
                        request.then(() => {
                            mutateSubGroups()
                        })
                        return request
                    }}
                    okText="ลบ"
                    cancelText="ยกเลิก"
                >
                    <Button
                        size={'small'}
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </>
        },
    ];

    return <>

        <Modal
            visible={openCreateGroupModal}
            title="สร้างกลุ่มอาชีพใหม่"
            okText="สร้าง"
            cancelText="ยกเลิก"
            confirmLoading={isCreatingLoading}
            onCancel={() => {
                setOpenCreateGroupModal(false)
            }}
            onOk={() => {
                createGroupForm
                    .validateFields()
                    .then(values => {
                        handleCreateGroup(values.name);
                    })
            }}
        >
            <Form
                form={createGroupForm}
                layout="vertical"
                name="createGroupForm"
            >
                <Form.Item
                    name="name"
                    label="ชื่อกลุ่มอาชีพ"
                    rules={[{required: true, message: 'โปรดระบุชื่อกลุ่มที่ต้องการสร้าง'}]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>

        <Modal
            visible={openCreateSubGroupModal}
            title="สร้างกลุ่มย่อยใหม่"
            okText="สร้าง"
            cancelText="ยกเลิก"
            confirmLoading={isCreatingLoading}
            onCancel={() => {
                setOpenCreateGroupModal(false)
            }}
            onOk={() => {
                createSubGroupForm
                    .validateFields()
                    .then(values => {
                        handleCreateSubGroup(values.name, values.group_id);
                    })
            }}
        >
            <Form
                form={createSubGroupForm}
                layout="vertical"
                name="createGroupForm"
            >
                <Form.Item
                    name="name"
                    label="ชื่อกลุ่มย่อย"
                    rules={[{required: true, message: 'โปรดระบุชื่อกลุ่มที่ต้องการสร้าง'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="group_id"
                    label="กลุ่มหลัก"
                    rules={[{required: true, message: 'โปรดระบุกลุ่ม'}]}
                >
                    <Select>
                        {
                            groups?.map(group => <Select.Option
                                value={group.id}
                            >
                                {group.name}
                            </Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>

        <Title level={2}>
            ฐานข้อมูล Skill Mapping
        </Title>

        <Row>
            <Col span={24}>

                    <div className={'card-container'} style={{marginTop: '24px'}}>

                        <Tabs type='card' style={{ margin: 0 }}>
                            <Tabs.TabPane tab={"กลุ่มหลัก"} key={'group'}>

                                <Row justify={'end'} style={{marginBottom: '24px'}}>
                                    <Col>
                                        <Space>
                                            <Button
                                                type={'primary'}
                                                onClick={() => {
                                                    setOpenCreateGroupModal(true);
                                                }}
                                            >
                                                <PlusOutlined/> สร้างกลุ่มอาชีพใหม่
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>

                                <Form
                                    form={groupEditingForm}
                                    name="editGroupForm"
                                >
                                    <Table
                                        columns={GroupTableColumns}
                                        loading={!groups}
                                        dataSource={groups}
                                        rowKey={'id'}
                                        expandable={{
                                            expandedRowRender: record => <>
                                                <Space size={[8, 16]} wrap>
                                                    <EditCommonCareers id={record.id} type={'group'} />
                                                    <EditSkills id={record.id} type={'group'} />
                                                    <EditJobPosts id={record.id} type={'group'} />
                                                    <EditSkillGrowth id={record.id} type={'group'} />
                                                    <EditIndustries id={record.id} type={'group'} />
                                                    <EditUniversities id={record.id} type={'group'} />
                                                    <EditIndustriesJobPosts id={record.id} type={'group'} />
                                                </Space>
                                            </>,
                                        }}
                                    />
                                </Form>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={"กลุ่มย่อย"} key={'sub_group'}>

                                <Row justify={'end'} style={{marginBottom: '24px'}}>
                                    <Col>
                                        <Space>
                                            <Button
                                                type={'primary'}
                                                onClick={() => {
                                                    setOpenCreateSubGroupModal(true);
                                                }}
                                            >
                                                <PlusOutlined/> สร้างกลุ่มย่อยใหม่
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>

                                <Form
                                    form={SubGroupEditingForm}
                                    name="editSubGroupForm"
                                >
                                    <Table
                                        columns={SubGroupTableColumns}
                                        loading={!sub_groups}
                                        dataSource={sub_groups}
                                        rowKey={'id'}
                                        expandable={{
                                            expandedRowRender: record => <>
                                                <Space size={[8, 16]} wrap>
                                                    <EditSkills id={record.id} type={'sub_group'} />
                                                    <EditJobPosts id={record.id} type={'sub_group'} />
                                                    <EditSkillGrowth id={record.id} type={'sub_group'} />
                                                    <EditIndustries id={record.id} type={'sub_group'} />
                                                    <EditUniversities id={record.id} type={'sub_group'} />
                                                    <EditIndustriesJobPosts id={record.id} type={'sub_group'} />
                                                </Space>
                                            </>,
                                        }}
                                    />
                                </Form>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={"อาชีพ"} key={'career'}>

                                <EditCareersTable />

                            </Tabs.TabPane>

                        </Tabs>

                    </div>
            </Col>
        </Row>
    </>
});

export default ManageSkillMapping;
